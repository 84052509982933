import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Hem: "/",
  "Analytics Consulting": "/se/analysrådgivning",
  "Händelsespårning": "/se/händelsespårning"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "se",
    href: "/se/händelsespårning"
  },
  {
    hreflang: "da",
    href: "/da/event-sporing"
  },
  {
    hreflang: "no",
    href: "/no/hendelsessporing"
  },
  {
    hreflang: "nl",
    href: "/nl/event-tracking"
  },
  {
    hreflang: "en",
    href: "/en/event-tracking"
  },
  {
    hreflang: "de",
    href: "/de/event-tracking"
  },
  {
    hreflang: "x-default",
    href: "/en/event-tracking"
  }
];

const EventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Händelsespårning i Google Analytics [Installationsguide ${currentYear}]`}
        description="Guide för att konfigurera händelsespårning för moderna Google Analytics tillsammans med Google Tag Manager. Handledning med skärmdumpar och förklaringar från certifierad expert."
        lang="se"
        canonical="/se/händelsespårning"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2024-06-13T04:32:43.188Z"
        dateModified="2024-06-13T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="händelsedata graf från Google Analytics händelserapportering"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Händelsespårning</H>
        <p>
          Att konfigurera händelsespårning i Google Analytics är avgörande för dataanalys eftersom det används för att spåra användarinteraktioner. Att analysera sidvisningar, sessioner och besökare ger bara en översikt utan kontext om en webbplatsbesökares engagemang.
        </p>{" "}
        <p>
          Detta löses genom att samla in ytterligare data från anpassade händelser som fångar användarinteraktioner, såsom länk-klick till exempel.
        </p>
        <p>
          Händelser representerar användarinteraktioner eller förekomster.
          <br />
          Vanligtvis är de viktigaste interaktionerna relaterade till webbplatsens mål.
          <br /> Därför sätter vi upp anpassad händelsespårning för att använda det som en prestationsindikator för att se om våra mål uppnås.
        </p>
        <p>
          Händelser kan grupperas i <strong>hårda konverteringar</strong> och <strong>mjuka konverteringar</strong> för att beakta deras relation till webbplatsens övergripande mål.
        </p>
        <p>
          Eftersom händelser ofta är grunden för att övervaka konverteringar i en e-handels- eller remarketingkontext, kallas det ibland för konverteringsspårning.
          <br />Jag gör skillnad mellan konverteringsspårning och att spåra en händelse, eftersom en konvertering också kan vara en sidvisning eller en <b>passiv</b> förekomst, till exempel att spendera en viss tid på en landningssida.
        </p>
        <p>
          För att illustrera vad händelsespårning innebär, kommer jag att visa hur man ställer in <strong>klickspårning</strong> på en skicka-knapp. Därefter bör du kunna ställa in dina egna unika klickhändelser på vilket element du vill - så låt oss börja!
        </p>
        <H as="h2">Vad är händelsespårning i Google Analytics?</H>
        <p>
          En händelse kan antingen vara en <strong>aktiv användarinteraktion</strong> eller en <strong>passiv förekomst</strong>.
          Att spåra dem innebär att övervaka eller räkna förekomsterna och sätta dem i relation till användare, besök och andra dimensioner för senare analys.
        </p>
        <p>Eftersom du är fri att konfigurera händelser som du vill, kan de ta många olika former.</p>
        <H as="h3">Exempel</H>
        <ul>
          <li>lägga till en produkt i en varukorg</li>
          <li>registrera sig på en e-postlista</li>
          <li>skrolla till botten av en artikel</li>
          <li>klicka på play i en video</li>
          <li>användarinloggning</li>
          <li>klicka på en bild för att zooma</li>
          <li>skicka ett kontaktformulär</li>
          <li>klicka på en utgående länk</li>
          <li>skriva ut en artikel</li>
          <li>ladda ner en fil</li>
        </ul>
        <p>
          Som du kan se är händelser vanligtvis kopplade till något användaren <em>gör</em>, baserat på övervakning...
        </p>
        <ul>
          <li>musklick</li>
          <li>musrörelser</li>
          <li>skrollning</li>
          <li>webbläsarnavigering</li>
          <li>tangentbordsinmatningar</li>
        </ul>
        <p>
          Händelser kan också vara <em>passiva</em>, utan att användaren gör något aktivt.
          <br />
          Till exempel när användaren spenderar en viss <strong>tid på en sida</strong> eller när ett formulär returnerar ett <strong>fel</strong>. Även när ett <strong>chattbot-fönster</strong> öppnas för att prata med besökaren, finns det ingen aktivitet från användaren.
        </p>
        <p>Även om en <b>sidvisning</b> tekniskt sett också är en interaktion, räknar den mest använda Universal Google Analytics en sidvisning inte som en händelse. Men det nya Google Analytics App + Web (för närvarande i Beta) använder en annan datamodell som också spårar sidbesök som händelser.</p>
        <H as="h2">Händelsetyper</H>
        <p>Vi kan skilja mellan två typer av händelser:</p>
        <H as="h6">Aktiva händelser (med användarinteraktion)</H>
        <ul>
          <li>musklick, skrollspårning och musrörelser</li>
          <li>skärmröring</li>
          <li>tangentbordsinmatningar</li>
        </ul>
        <H as="h6">Passiva händelser</H>
        <ul>
          <li>nå en fördefinierad tidsgräns</li>
          <li>Serverresponser</li>
          <li>Programmatisk validering</li>
          <li>Exekvering av tredjepartsskript</li>
        </ul>
        <p>
          Observera att genom att spåra aktiva och passiva händelser kan du återskapa en stor del av{" "}
          kundresan
          . <br />
          Sådan data gör det möjligt för dig att dra slutsatser om användarens upplevelse från början till slut. Om du hittar några
          brister i resan kan du nu vidta åtgärder för att minska dessa negativa upplevelser.
        </p>
        <H as="h2">Struktur för Google Analytics-händelser</H>
        <p>
          Varje händelse i Google Analytics innehåller några händelsefält, eller bitar av information om händelsen, som vi kan
          använda för att beskriva förekomsten:
        </p>
        <ul>
          <li>
            <strong>händelsekategori</strong> - för att kategorisera händelser i grupper. I sammanhanget av ett kontaktformulär, till exempel, vill du gruppera alla händelser i en händelsekategori <em>kontaktformulär</em>
          </li>
          <li>
            <strong>händelseåtgärd</strong> - för att beskriva interaktionen, t.ex. <em>formulärinlämning</em>
          </li>
          <li>
            <strong>händelseetikett</strong> - för att lägga till ytterligare information om interaktionen. Till exempel, om användaren skickar <em>adressen till sin webbplats</em> tillsammans med formuläret, använder du det för att skilja mellan de mottagna formulären
          </li>
          <li>
            <strong>händelsevärde</strong> - (<em>valfritt</em>) för att lägga till ett numeriskt värde till informationen. Till exempel, om i genomsnitt varje 25:e kontakt leder till en konvertering på 100$, kan vi tilldela ett värde på <em>4</em>{" "}
            till varje händelse.
          </li>
          <li>
            <strong>händelse icke-interaktion</strong> - för att skilja mellan en aktiv eller passiv användarinteraktion.{" "}
            <br />
            Som standard är det inställt på <code>false</code>, vilket innebär att händelsen räknas som aktiv. <strong>Viktigt:</strong> Aktiva händelser påverkar beräkningen av en sidas <b><Link to="/se/wiki-analytics/avvisningsfrekvens">avvisningsfrekvens</Link></b>, genom att sänka den. Så om du någonsin kämpar med en orealistiskt låg avvisningsfrekvens, finns det en chans att vissa händelsers användarinteraktion är inställd på <code>true</code> av misstag.
          </li>
        </ul>
        <H as="h2">Implementera händelsespårning i Google Analytics</H>
        <p>Det finns två möjliga sätt att implementera anpassad händelsespårning:</p>
        <ol>
          <li>
            <strong>Implementera det</strong> med javascript-snippets på alla relevanta HTML-element tillsammans med en onclick-händelsehanterare - <strong>jag rekommenderar inte detta</strong> tillvägagångssätt, eftersom det är felbenäget och inte skalbart. Om du ändå vill göra detta kan du hitta kodexempel i{" "}
            <strong>Googles dokumentation för händelsespårning</strong>:
          </li>
        </ol>
        <ul>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
              analytics.js händelsedokumentation (legacy)
            </a>
          </li>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
              gtag.js händelsedokumentation
            </a>
          </li>
        </ul>
        <ol start="2">
          <li>
            <strong>Ställa in det med Google Tag Manager</strong> eller ett annat <Link to="/se/tagghantering">tagghanteringssystem</Link> av ditt val. <br />
            Enkelhet och flexibilitet vid implementering av ändringar, samt skalbarhet och enkel integration med
            tredjepartstjänster gör det till den föredragna implementeringsmetoden.
          </li>
        </ol>
        <p>
          Jag kommer att förklara hur man spårar händelser med Google Tag Manager, eftersom det är det mest använda tagghanteringssystemet som alla kan få tillgång till.
        </p>
        <p>Om du vill lära dig mer om GTM, som hur man ställer in det och spårar saker som utgående länkar och nedladdningar, läs min <Link to="/se/google-tag-manager-setup">Google Tag Manager-handledning</Link>.</p>
        <H as="h2">Guide för att spåra händelser med Google Tag Manager</H>
        <p>
          Att ställa in anpassad händelsespårning kan delas upp i att skapa en <strong>trigger</strong> för händelsen, samt att skapa en <strong>händelsetagg</strong>, som fyller i händelseegenskaperna (
          <em>kategori, åtgärd, etikett</em>, etc.). <br />
          Värdena för händelseegenskaperna kan antingen tilldelas statiskt eller dynamiskt - där det senare är att föredra för att skala konfigurationen över större webbplatser.
        </p>
        <p>
          Som ett exempel kommer vi att implementera Google Analytics-spårning för ett knapptryck.{" "}
          <strong>Klickspårning</strong> är den mest använda formen av användarbeteendespårning, så exemplet bör vara tillämpligt på många andra användningsfall.
        </p>
        <p>Vid denna tidpunkt antar jag att du redan har skapat ett spårnings-ID och att spårningskoden har implementerats på hela webbplatsen när du <Link to="/se/google-analytics-setup">ställde in Google Analytics</Link> med GTM.</p>

        <H as="h3">Konfigurera händelsetriggern i Google Tag Manager</H>
        <ul>
          <li>
            Först måste vi logga in på vår <a href="https://tagmanager.google.com">Google Tag Manager-kontainer</a>{" "}
            och <strong>skapa en ny trigger</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
          alt="Skapa ny trigger i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            Ge din trigger ett <strong>namn</strong>, till exempel <strong>Klick - Knapp</strong>, och klicka på{" "}
            <strong>triggerkonfiguration</strong> fältet för att välja en triggertyp. Du kommer att se en lista över triggertyper som glider in från höger.
          </li>
          <li>
            Välj <strong>Alla element</strong> som typ och välj att{" "}
            <strong>triggern bara utlöses vid vissa klick</strong>.
          </li>
          <li>
            Välj sedan i den första rullgardinsmenyn <strong>Klickelement</strong>. Om det inte är listat, klicka bara på{" "}
            <strong>Välj inbyggd variabel…</strong> och aktivera det.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-variable.png"
          alt="Trigger variabel i Google Tag Manager skärmdump"
          className="article-img"
        />
        <ul>
          <li>
            I den andra rullgardinsmenyn, välj <strong>matchar CSS-selektor</strong>.<br />
            Nu låt mig förklara vad vi gör:
            <br />
            Vi försöker skapa en klicktrigger för ett specifikt HTML-element på din webbplats - det du vill
            spåra klick på. I mitt exempel är det en <strong>knapp</strong>, men det kan också vara en{" "}
            <strong>bild</strong> eller en <code>&lt;div&gt;</code> tag, eller vilken annan HTML-tagg som helst - så frågan är: Hur
            talar vi om för Google Tag Manager (GTM) vilket element som ska observeras?
            <br />
            Svaret är: <strong>CSS-selektorer</strong> - Vet du inte vad det är? Det är bara ett programmeringssätt
            att specificera HTML-element på sidan och jag kommer att visa dig ett sätt att få CSS-selektorn för vilket element som helst med bara några få musklick.
          </li>
          <li>
            I Googles Chrome-webbläsare, <strong>högerklicka</strong> på valfritt element du vill spåra klick på och
            välj <strong>inspektera</strong>. Chrome Developer Tools öppnas och som standard kommer HTML-elementet att vara förvalt.
          </li>
          <li>
            Sedan högerklickar du helt enkelt på elementet och väljer <strong>Kopiera</strong> &gt;{" "}
            <strong>Kopiera JS-sökväg</strong> (undvik <em>kopiera selektor</em> - det returnerar felaktiga selektorer från tid till annan).
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
            alt="Kopiera CSS-selektor för knappklickspårning"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Om du klistrar in innehållet i ditt urklipp i en textredigerare kommer du att se att du kopierade något som:
            <br />
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
            <br />
            <br />
            Vi vill ta den del som är inuti parenteserna, utan “”. Så denna del:
            <br />
            <br />
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>Detta är CSS-selektorn</strong> vi behöver klistra in i det sista fältet i vår triggerkonfiguration i GTM (<em>se nedan</em>).
            <br />
            <strong>Kopiera</strong> det värdet och klistra in det. Klicka på <strong>spara</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Konfiguration av händelsetrigger i Google Tag Manager skärmdump"
          className="article-img"
        />
        <p>Notera att du kan göra detta med vilket element som helst och ställa in vilken händelsetrigger som helst i GTM som du vill?</p>
        <p>
          Vi är inte klara än. Efter att ha ställt in triggervillkoren för vår händelse måste vi konfigurera Google Analytics-händelsetaggen för att säkerställa att rätt data skickas till Google Analytics-egendomen.
        </p>
        <H as="h3">Skapa en anpassad händelsetagg</H>
        <p>
          Nu när vi har en trigger måste vi kombinera den med en faktisk händelsetagg. Du kan tänka på triggers och taggar som <em>när</em> och <em>vad</em> i vårt exempel: <br /> Triggern definierar <em>när</em> vi vill spåra en händelse (<em>när vår knapp klickas</em>), och nu kommer vi att definiera en GA-händelse, som beskriver{" "}
          <em>vad</em> vi gör: <em>Skicka en händelse till GA inklusive alla rätta värden för händelseegenskaperna.</em>
        </p>
        <p>Låt oss börja.</p>
        <ul>
          <li>
            I Google Tag Manager, gå till taggmenyn och <em>skapa en ny tagg</em>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
          alt="Google Tag Manager skapa anpassad händelsetagg"
          className="article-img"
        />
        <ul>
          <li>
            Ge taggen ett <strong>namn</strong> som <em>Button Event</em> och klicka på{" "}
            <strong>taggkonfiguration</strong>.
          </li>
          <li>
            En panel från höger sida kommer att glida in med en lista över möjliga taggtyper att välja mellan. Välj{" "}
            <strong>Google Analytics - Universal Analytics</strong>.
          </li>
          <li>
            Tillbaka i taggkonfigurationen, ställ in <strong>spårningstypen</strong> till <strong>händelse</strong>. Nu kommer fler fält att visas för händelsedata.
          </li>
          <li>
            Låt oss tilldela statiska värden för <strong>händelsekategori</strong> och <strong>åtgärd</strong> och låt oss göra
            <strong>händelseetiketten</strong> dynamisk.
            <br />
            Kalla <strong>kategorin</strong> <em>klickhändelser</em>, till exempel, <strong>åtgärden</strong> är bara{" "}
            <em>klick</em> och för <strong>etiketten</strong> klicka på Lego-klossymbolen bredvid.
          </li>
          <li>
            Vi vill att etiketten alltid ska innehålla texten som är skriven på det klickade HTML-elementet, så om vi spårar flera knappar kan vi skilja mellan dem.
            <br />
            Efter att ha klickat på Lego-klossen kommer en panel att glida in för att <strong>välja en variabel</strong>.
            Klicka på <strong>inbyggda</strong> i det övre högra hörnet, om du inte redan ser{" "}
            <strong>klicktext</strong> i listan över variabler (då bara <strong>välj den</strong> och hoppa över följande steg).
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
          alt="inbyggd variabel för händelseetikett i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            I listan över inbyggda variabler, aktivera <strong>click text</strong> och GTM kommer att lägga till den variabeln i etikettfältet med klamrar, så här: <code>{"{{Click Text}}"}</code> - Dubbla klamrar är det interna sättet i GTM att referera till variabler.
          </li>
          <li>
            <strong>Värdefältet</strong> i taggkonfigurationen kan fyllas med ett värde som händelseklicken kan representera. <br />
            I mitt exempel lämnar jag fältet <strong>tomt</strong> eftersom jag inte har något meningsfullt monetärt värde för knappklicken.
          </li>
          <li>
            Om händelsen är <strong>interaktiv</strong>, välj <strong>false</strong> för icke-interaktionshändelse. Om det är en <strong>passiv händelse</strong>, välj <strong>true</strong>.
          </li>
          <li>
            I variabeln <strong>Google Analytics-inställningar</strong>, välj den variabel du konfigurerade när du{" "}
            <a href="/se/google-analytics-setup">konfigurerade Google Analytics</a>.
          </li>
          <li>
            Om du inte har konfigurerat en <strong>inställningsvariabel</strong>, markera bara <em>Aktivera åsidosättning av inställningar i denna tagg</em> och lägg till spårnings-ID (t.ex. <em>UA-12345678-91</em>) från din{" "}
            <Link to="/se/google-analytics-setup#where-to-get-the-google-analytics-tracking-code">
              Google Analytics-spårningskod
            </Link>
            .
          </li>
          <li>
            Slutligen, scrolla ner till <strong>utlösnings</strong>-konfigurationen, <strong>klicka på den</strong> och välj den utlösare vi konfigurerade tidigare: <em>Click - Button</em>
          </li>
        </ul>
        <p>Hela konfigurationen för händelseetikett på en gång:</p>
        <ImgContainerFixed width="371px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
            alt="GTM händelseetikettkonfiguration"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Klicka nu på <strong>spara</strong> i det övre högra hörnet och du är klar med att konfigurera GA-händelsen.
          </li>
        </ul>
        <p>Perfekt! Nästa steg är att dubbelkolla om vår onclick-spårningsinställning fungerar.</p>
        <H as="h2">Felsöka händelser</H>
        <p>
          För att felsöka inställningen, gå in i <strong>förhandsgranskningsläge</strong> från Google Tag Manager-översikten:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-preview.png"
          alt="Google Tag Manager förhandsgranskningsläge"
          className="article-img"
        />
        <br />
        <p>
          Därefter besöker vi sidan som innehåller skicka-knappen. På grund av Google Tag Manager förhandsgranskningsläge har vi vissa <Link to="/se/wiki-analytics/kakor">cookies</Link> inställda i vår webbläsare, vilket kommer att öppna ett felsökningsfönster längst ner på sidan:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="Google Tag Manager-felsökare för händelser"
          className="article-img"
        />
        <br />
        <p>
          Om du nu <strong>klickar på knappen</strong> för att testa händelsespårningen, bör du se ett <strong>klick</strong> dyka upp i den vänstra sammanfattningspanelen, och huvudfelsökningsfönstret bör visa att vår händelse utlösts:
        </p>
        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="Google Tag Manager-felsökare för klickspårning"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Om du klickar på <strong>knapphändelsen</strong> kan du verifiera de händelsefält som skickades tillsammans med händelsen:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="Google Tag Manager felsökare händelsefält"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Ser du hur <strong>händelseetiketten</strong> har fyllts i automatiskt med "OK"? Det beror på att vi använde den inbyggda variabeln <strong>click text</strong> i Google Tag Manager, som alltid innehåller texten för det klickade elementet.
        </p>
        <p>Viktigare är att om du scrollar längre ner kan du verifiera reglerna för händelseutlösaren:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="Google Tag Manager händelseutlösarregler"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          <strong>TIPS</strong>: För att verifiera att ditt Google Analytics-konto framgångsrikt mottagit all data, kan vi besöka <strong>realtidsrapporten för händelser</strong> i Google Analytics. Om det har gått några minuter sedan senaste knappklicket, kan <strong>visningen för de senaste 30 minuterna</strong> vara till hjälp.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-realtime-report.png"
          alt="Google Analytics realtidsrapport för händelser"
          className="article-img"
        />
        <H as="h3">Validering av CSS-väljare</H>
        <p>
          Om felsökaren inte noterade dina knappklick och inte visade någon klickhändelse, är det troligen något fel med <strong>CSS-väljaren</strong>.<br />
          <strong>TIPS:</strong> du kan öppna <strong>Chrome Developer Tools</strong> på sidan med elementet du vill spåra genom att antingen trycka <strong>F12</strong> eller <strong>högerklicka</strong> &gt; <strong>inspektera</strong> och besöka <strong>element</strong>-fliken. Om du nu trycker <strong>CTRL+F</strong> eller <strong>CMD+F</strong> på Mac för att söka efter ett element, kan du helt enkelt <strong>klistra in din CSS-väljare i sökfältet</strong> så hoppar utvecklarverktygen automatiskt till elementet:
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="Google Developer Tools hitta element med CSS-väljare"
            className="article-img"
          />
        </ImgContainerFixed>
        <p>
          På så sätt kan du verifiera om din CSS-väljare fungerar. Om utvecklarverktygen inte hoppar till något element i koden, är det något fel med väljaren. Det finns också <strong>webbläsartillägg</strong> som kan hjälpa dig att få rätt väljare.
        </p>
        <p>
          Efter att ha felsökt och fixat väljare bör vi slutligen ha klickspårningen igång och kan återgå till Google Tag Manager för att <strong>lämna förhandsgranskningsläget</strong> och <strong>skicka ändringarna</strong>.
        </p>
        <p>
          Grattis så här långt! Du har konfigurerat händelseövervakning för att klicka på en knapp baserat på en CSS-väljare!
        </p>
        <p>
          Med inställningen nu fungerande kan du{" "}
          <Link to="/se/google-analytics-setup#how-to-set-up-goals-in-google-analytics">ställa in händelsemål</Link> i GA! Google Analytics-mål är en användbar funktion, eftersom du kan bryta ner rapporter och se hur många konverteringar som har skett för ett givet delsegment.
        </p>
        <p>
          För att ställa in det, behöver du bara definiera ett anpassat Google Analytics-mål med kategori-, actions- och etikettfält som matchar din händelse och det kommer då att räknas som ett händelsemål.
        </p>
        <H as="h2">Fördelar &amp; Nackdelar med att spåra med CSS-väljare</H>
        <p>
          Det är viktigt att nämna att inställning av händelsespårning baserat på CSS-väljare inte är det mest robusta sättet.
        </p>
        <p>
          Hela händelseinställningen kommer att sluta fungera om CSS-väljaren inte fungerar. Det är ett <em>kritiskt beroende</em>, vilket representerar en svaghet i detta tillvägagångssätt.
        </p>
        <p>Men varför skulle väljaren någonsin sluta fungera?</p>
        <p>
          Ju större webbplatsen är, desto fler ändringar sker på webbplatsen, och desto fler personer är inblandade - möjligen samtidigt i olika team.
          <br /> Så snart en ändring påverkar strukturen på vår väljare, kommer den att sluta fungera.
        </p>
        <p>
          En frontend-utvecklare kan mycket väl göra ändringar på sidan någon dag i framtiden, vilket kan få väljaren att sluta fungera. Därför skulle det vara mer robust att lägga till en <strong>onclick händelsehanterare</strong> till elementet, som skickar alla händelseegenskaper till ett
          <strong>
            <Link to="/se/data-lager">datalager</Link>
          </strong>. Detta är det rekommenderade tillvägagångssättet för viktiga KPI:er och för stora organisationer där ändringar inte övervakas.
        </p>
        <p>Avancerade webbanalysimplementeringar som integrerar databaser och tredjepartsdatakällor använder ett <Link to="/se/data-lager#event-tracking-basend-on-a-data-layer">datalager för att spåra händelser</Link>. Läs den artikeln för den nödvändiga händelsespårningskoden.</p>
        <p>Användning av ett datalager är mer säkert och pålitligt men kräver en grundlig implementering.</p>
        <p>Istället för att förlita sig på CSS-väljare, använder du händelselyssnare för <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">webbläsarhändelser</a> som en utlösare. GTM använder också klickhändelselyssnare i bakgrunden när du ställer in en klickutlösare, men när du baserar spårning på ett datalager kan du mer fritt välja vilka webbläsarhändelser som ska spåras.</p>
        <p>Särskilt för en enkel sidas applikation (SPA) utan sidladdningar mellan sidnavigering, kan ett datalager användas väl för att spåra <b>virtuella sidvisningar</b> som händelser och få dem att räknas som vanliga sidvisningar i Google Analytics.</p>
        <p>
          För mindre webbplatser är ansträngningen att implementera ett datalager inte alltid rättfärdigad. I sådana fall är händelsespårning baserad på CSS-väljare ett giltigt tillvägagångssätt.
        </p>
        <p>
          <strong>Fördelen</strong> med att använda CSS-väljare är tydligt <strong>lättheten</strong> i implementeringen och hur <strong>snabbt</strong> du kan börja samla in data. Detta kan vara användbart också om en datalagerimplementering kan ta tid på grund av brist på resurser, eftersom du kan redan börja samla in en del av data bara med CSS-väljare.
        </p>
        <p>
          <strong>Nackdelen</strong> är att detta sätt är nästan <strong>benäget att sluta fungera</strong> någon dag, beroende på hur många ändringar som implementeras och hur många team som arbetar på webbplatsen.
        </p>
        <p>
          Men om du ställer in webbspårning för din egen webbplats och du har kontroll över ändringarna, tveka inte att följa denna metod. Var bara medveten om att ändringar i sidans HTML-struktur kan bryta CSS-väljaren.{" "}
        </p>
        <p>
          Företag med budget och höga krav på datakvalitet bör dock välja ett datalager som grund för deras händelsespårningsinställning.
        </p>
        <H as="h2">Händelserapporter i Google Analytics</H>
        <p>
          Nu när vi har ställt in spårning för användarinteraktioner, måste vi se till att vi håller koll på mängden händelser som skickas till Google Analytics-kontot.{" "}
        </p>
        <p>
          Det bästa sättet att göra detta är antingen med den inbyggda <strong>händelserapporten</strong>, med en <strong>anpassad rapport</strong> eller en Google Analytics <strong>dashboard</strong>.
        </p>
        <p>
          I <strong>Google Analytics</strong>, kan du gå till <strong>Beteende</strong> &gt; <strong>Händelser</strong> &gt; <strong>Topphändelser</strong> för att hitta händelsedata du spårade:
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report.png"
          alt="Google Analytics händelsespårningsrapport översikt"
          className="article-img"
        />
        <br />
        <p>
          Eftersom det inte finns något standard <b>händelsenamn</b> tillgängligt i GA, kommer du först att se dina händelsekategorier. Du kan också välja den primära dimensionen att vara <strong>händelseåtgärd</strong> eller <strong>händelseetikett</strong> för att gräva djupare i rapporten.
        </p>
        <p>
          Notera att händelserapporten ger oss en kolumn med <strong>Genomsnittligt värde</strong>, vilket är praktiskt om vi behöver det genomsnittliga värdet av våra klickhändelser. Detta kan vara relevant för en webbapp som beräknar priser, där det slutliga resultatet spåras med en händelse. På detta sätt kan du enkelt hitta det genomsnittliga värdet av användarnas beräkningar.{" "}
        </p>
        <p>
          Andra vanliga exempel på användbara genomsnittliga värden är t.ex. filterinställningar för priser eller storlekar i en e-handelsbutik.{" "}
        </p>
        <p>
          Kom ihåg att ett <strong>händelsevärde</strong> kanske verkar orelaterat till en början, men det kan ändå finnas ett relaterat värde att tilldela händelsen, eftersom det skulle vara hjälpsamt att kontrollera genomsnittliga värden över hela linjen.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default EventTracking;
